import React, { useState, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { isCancel } from 'services/bff';
import { CSVReader } from 'react-papaparse';
import classes from 'components/base/fileUpload/fileUpload.module.scss';
import config from 'config';
import constants from 'services/constants';
import errors from 'services/errors';
import helpers from 'services/helpers';
import moment from 'moment';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import Button from 'components/base/button/button';
import ErrorLabel from 'components/base/errorLabel/errorLabel';
import FieldLabel from 'components/base/fieldLabel/fieldLabel';
import Hint from 'components/base/hint/hint';
import Legend from 'components/base/legend/legend';

const FileUpload = ({
  acceptExtendedCsv,
  addDate,
  description,
  hideHint,
  hideLabel,
  hideTooltip,
  hint,
  label,
  maxTagsAllowed,
  modalMessage,
  modalTitle,
  notes,
  onUpload,
  pending,
  setAnimalsToChange,
  setModal,
  setPending,
  species
}) => {
  const { ready, t } = useTranslation();

  const sessionBreeds = storeService.session.get.breedsAll()[species.id];

  const [selectedFile, setSelectedFile] = useState([]);
  const [invalidFileError, setInvalidFileError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [maxRecordsError, setMaxRecordsError] = useState('');
  const buttonRef = createRef();

  const get = {
    dobAndAgeToDisplay: (dob, dateToCheck) => {
      if (dob) {
        if (!isNaN(dob)) {
          const currentYear = dateToCheck ? moment(dateToCheck).year() : moment().year();

          return [
            dob,
            parseInt(dob) <= currentYear ? helpers.date.ageInYears(dob) : '',
            parseInt(dob) <= currentYear
          ];
        }

        if (dob.includes('-')) {
          const dobToDisplay = helpers.date.format(dob);
          const ageToDisplay = helpers.get.age(helpers.date.momentToISODate(moment(dob)));

          if (dobToDisplay !== 'Invalid date') {
            const validDate = !helpers.date.is.inTheFuture(dob) && (!dateToCheck || !helpers.date.is.afterDate(dob, dateToCheck));

            return [
              dobToDisplay,
              validDate ? ageToDisplay : '',
              validDate
            ];
          }

          return [
            dob,
            '',
            false
          ];
        }

        if (isNaN(dob)) {
          return [
            dob,
            '',
            false
          ];
        }

        return [
          dob,
          '',
          true
        ];
      }

      return [
        '',
        '',
        true
      ];
    },

    genderName: (gender) => {
      if (!gender) {
        return '';
      }

      const lcGender = gender.toLowerCase();

      if (lcGender === 'm' || lcGender === constants.option.gender.male) {
        return 'Male';
      }
      if (lcGender === 'f' || lcGender === constants.option.gender.female) {
        return 'Female';
      }

      return null;
    }
  };

  const handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = (data) => {
    const regex = /^.*\.(csv)$/;

    if (regex.test(buttonRef.current.state.file.name)) {
      let parsedData = [];

      setSelectedFile([]);

      if (acceptExtendedCsv) {
        // if (!Object.keys(data[0].data).filter((item) => ['tagnumber', 'breed', 'gender', 'dob'].includes(item.toLowerCase())).length > 0) {
        //   const columnHeaders = [];
        //   const tempData = [
        //     Object.keys(data[0].data).filter((field) => field !== '__parsed_extra'),
        //     ...data.map((row) => ([
        //       ...Object.values(row.data).filter((field) => typeof field === 'string'),
        //       ...(row.data?.__parsed_extra ? row.data.__parsed_extra : [])
        //     ]))
        //   ];
        //
        //   for (const row of tempData) {
        //     row.forEach((field, index) => {
        //       const trimmedField = field.trim();
        //
        //       if (trimmedField) {
        //         if (trimmedField.substring(0, 2).toLowerCase() === 'uk' && !columnHeaders.includes('tagNumber')) {
        //           columnHeaders[index] = 'tagNumber';
        //         } else if ([constants.option.gender.male, constants.option.gender.female, 'm', 'f'].includes(trimmedField.toLowerCase()) && !columnHeaders.includes('genderName')) {
        //           columnHeaders[index] = 'genderName';
        //         } else if (helpers.date.is.validCsvDate(trimmedField) && !columnHeaders.includes('dobToDisplay')) {
        //           columnHeaders[index] = 'dobToDisplay';
        //         } else if (!columnHeaders.includes('breedName')) {
        //           columnHeaders[index] = 'breedName';
        //         }
        //       }
        //     });
        //
        //     if (columnHeaders.length === 4) {
        //       break;
        //     }
        //   }
        //
        //   parsedData = tempData
        //     .map((row) => {
        //       const tempItemData = {};
        //
        //       columnHeaders.forEach((column, index) => {
        //         tempItemData[column] = row[index]?.trim();
        //       });
        //
        //       return tempItemData;
        //     })
        //     .filter((row) => row.tagNumber);
        // } else {
        //   parsedData = data
        //     .filter((row) => row.data.tagNumber)
        //     .map((row) => ({
        //       tagNumber: row.data.tagNumber?.trim(),
        //       breedName: row.data.breed?.trim(),
        //       genderName: row.data.gender?.trim(),
        //       dobToDisplay: row.data.dob?.trim()
        //     }));
        // }

        parsedData = data
          .map((row) => {
            for (const [key, value] of Object.entries(row.data).filter((field) => field[0] !== '__parsed_extra')) {
              let parsedKey = key.trim().toLowerCase();

              if (parsedKey === 'tagnumber') {
                parsedKey = 'tagNumber';
              }

              delete row.data[key];

              row.data[parsedKey] = value?.trim().replace(/[\r]/gm, '');
            }

            let dob = row.data.dob;

            if (!isNaN(dob) && dob.length === 2) {
              dob = '20' + dob;
            } else if (row.data.dob?.includes('/')) {
              const dateParts = row.data.dob?.split('/');

              if (dateParts[2].length === 2) {
                dateParts[2] = '20' + dateParts[2];
              }

              dob = dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
            }

            const matchedBreed = row.data.breed ? sessionBreeds.find((breed) => breed.name.toLowerCase() === row.data.breed.toLowerCase()) : null;
            const [dobToDisplay, ageToDisplay, validDate] = get.dobAndAgeToDisplay(dob, addDate);

            return {
              tagNumber: row.data.tagNumber,
              breedName: matchedBreed?.name || row.data.breed,
              genderName: get.genderName(row.data.gender) || row.data.gender,
              dob,
              dobToDisplay,
              ageToDisplay,
              validDate
            };
          })
          .filter((row) => row.tagNumber)
          .filter((row) => Object.values(row).some((field) => field));
      } else {
        data.forEach((element) => {
          element.data.forEach((el) => {
            const trimmedElement = el.replaceAll(' ', '').toLowerCase();

            if (trimmedElement.length > 0 &&
              (
                helpers.checkTags(trimmedElement)
              )
            ) {
              parsedData.push(el);
            }
          });
        });
      }

      setSelectedFile(parsedData);
      setFileTypeError(false);

      if (parsedData.length === 0) {
        setInvalidFileError(true);
      } else {
        setInvalidFileError(false);
      }
    } else {
      setFileTypeError(true);
      setFileError(false);
      setInvalidFileError(false);
    }
  };

  const uploadTags = () => {
    if (acceptExtendedCsv) {
      if (selectedFile.length > config.MAX_UPLOAD_RECORDS_EXTENDED_CSV) {
        setMaxRecordsError('error.maxRecordsNumber2');
      } else {
        setPending(true);
        setMaxRecordsError('');

        helpers.validate.tagNumbers(species.name, [], selectedFile.map((item) => item.tagNumber))
          .then((res) => {
            setPending(false);
            setFileError(false);
            setSelectedFile([]);

            const newTags = res.data.tagNumbers.map((tag, index) => ({
              ...selectedFile[index],
              tagNumber: tag.formattedDevice.tagNumber ? tag.formattedDevice.tagNumber : tag.originalIdentifier,
              invalid: [
                tag.state === 'Invalid' ? 'tagNumber' : '',
                selectedFile[index].breedName && !sessionBreeds.map((breed) => breed.name.toLowerCase()).includes(selectedFile[index].breedName.toLowerCase()) ? 'breedName' : '',
                selectedFile[index].genderName && ![constants.option.gender.male, constants.option.gender.female, 'm', 'f'].includes(selectedFile[index].genderName.toLowerCase()) ? 'genderName' : '',
                !selectedFile[index].validDate ? 'dob' : ''
              ].filter((item) => item),
              duplicates: tag.duplicateWithIndexes,
              index
            }));

            if (onUpload && typeof onUpload === 'function') {
              onUpload();
            }

            setAnimalsToChange(newTags);
          })
          .catch((error) => {
            if (!isCancel(error)) {
              setPending(false);
              errors.BFF(error, setModal);
            }
          });
      }
    } else {
      const uniqueTags = [...new Set(selectedFile)].map((tag) => tag.trim().replace(/\s\s+/g, ' '));

      if (maxTagsAllowed && uniqueTags.length > maxTagsAllowed) {
        setMaxRecordsError('Unable to upload more than ' + maxTagsAllowed + ' tag numbers at a time. Please reduce the number of tags.');
      } else if (uniqueTags.length > config.MAX_UPLOAD_RECORDS) {
        setMaxRecordsError('error.maxRecordsNumber');
      } else {
        setPending(true);
        setMaxRecordsError('');

        helpers.validate.tagNumbers(species.name, [], uniqueTags)
          .then((res) => {
            if (helpers.response.isValid(res.data, setModal, setPending)) {
              setPending(false);
              setFileError(false);
              setSelectedFile([]);

              const newTags = res.data.tagNumbers.map((tag) => ({
                tagNumber: tag.formattedDevice.tagNumber ? tag.formattedDevice.tagNumber : tag.originalIdentifier,
                valid: tag.state
              }));

              setAnimalsToChange(newTags);
            }
          })
          .catch((error) => {
            if (!isCancel(error)) {
              setPending(false);
              errors.BFF(error, setModal);
            }
          });
      }
    }
  };

  return (
    <>
      {ready &&
        <fieldset role="group">
          <Legend
            legend="label.uploadAFile"
            tooltip={hideTooltip ? '' : 'tooltips.tagApplications.uploadFile'}
            tooltipPosition="tooltipBottom"
          />

          {description &&
            <div className={classes.description}>
              {description.map((desc) => (
                <p key={desc}>
                  {helpers.text.getContentsLink(
                    t,
                    desc,
                    () => setModal({
                      classNames: ['wide'],
                      modalTitle: modalTitle,
                      modalMessage: modalMessage
                    })
                  )}
                </p>
              ))}
            </div>
          }

          {!hideHint &&
            <Hint
              hint={hint ? hint : 'label.uploadDesc'}
            />
          }
          <div className={classes.fileUpload}>
            {!hideLabel &&
              <FieldLabel
                label={label ? label : 'label.chooseUpload'}
                name="uploadFile"
              />
            }
            <div className={classes.fileBrowser}>
              <CSVReader
                config={acceptExtendedCsv
                  ? {
                    delimiter: ',',
                    encoding: 'utf8',
                    header: true
                  }
                  : {}
                }
                noClick
                noDrag
                onFileLoad={handleOnFileLoad}
                ref={buttonRef}
              >
                {({ file }) => (
                  <div className={classes.browseWrap}>
                    <Button
                      buttonType="upload"
                      disabled={pending}
                      id="uploadFile"
                      label="label.browse"
                      onClick={handleOpenDialog}
                    />{' '}
                    {selectedFile.length > 0 && file?.name}
                  </div>
                )}
              </CSVReader>
              <Button
                buttonType="secondary"
                disabled={selectedFile.length === 0 || fileTypeError || pending}
                label="button.upload"
                onClick={uploadTags}
              />
            </div>

            <div className={classes.notes}>
              {notes && <div> {notes}</div> }
            </div>

            {invalidFileError &&
              <ErrorLabel
                label="error.invalidFile"
              />
            }
            {fileError &&
              <ErrorLabel
                label="error.fileUploadedHasInvalidTags"
              />
            }
            {fileTypeError &&
              <ErrorLabel
                label="error.fileTypeError"
              />
            }
            {maxRecordsError &&
              <ErrorLabel
                label={maxRecordsError}
              />
            }
          </div>
        </fieldset>
      }
    </>
  );
};

FileUpload.propTypes = {
  acceptExtendedCsv: PropTypes.bool,
  addDate: PropTypes.string,
  description: PropTypes.array,
  hideHint: PropTypes.bool,
  hideTooltip: PropTypes.bool,
  label: PropTypes.string,
  maxTagsAllowed: PropTypes.number,
  modalMessage: PropTypes.string,
  modalTitle: PropTypes.string,
  notes: PropTypes.string,
  onUpload: PropTypes.func,
  pending: PropTypes.bool.isRequired,
  setAnimalsToChange: PropTypes.func,
  setModal: PropTypes.func.isRequired,
  setPending: PropTypes.func.isRequired,
  species: PropTypes.object
};

export default FileUpload;
