import React from 'react';
import vetModuleHelper from 'services/vetModuleHelper';
import { BlobProvider } from '@react-pdf/renderer';
import bff, { isCancel } from 'services/bff';
import constants from 'services/constants';
import helpers from 'services/helpers';
import PropTypes from 'prop-types';
import storeService from 'services/storeService';
import Button from 'components/base/button/button';
import MovementDocumentPDF from 'templates/movementDocumentPDF';
import MovementDocumentDeerPDF from 'templates/movementDocumentDeerPDF';

const PDFButton = ({
  buttonType,
  label,
  referenceId,
  requestId,
  requestType,
  setModal
}) => {
  const species = storeService.session.get.species();
  let firstTimePDfDownload = React.useRef(false);
  const [data, setData] = React.useState({});
  const [attestationRecord, setAttestationRecord] = React.useState({});
  const [pending, setPending] = React.useState(false);
  let pdfUrl = React.useRef('');
  const [documentReference, setDocumentReference] = React.useState(null);

  const downloadUrl = (url, movementReference) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      movementReference + '.pdf'
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const generatePDF = (docRef) => {
    getMovementDetails(docRef);
  };

  const getMovementHolding = (data) => {
    return data.userHolding ? data.userHolding
      : (helpers.cph.isNonCph(data.sourceHolding)
        ? data.destinationHolding : data.sourceHolding);
  };

  const createPermitAddress = (nonCPHAddress) => {
    const result = { propertyName: 'NON-CPH Permit move', address1: nonCPHAddress };
    return result;
  };

  const getMovementDetails = (docReference) => {
    setPending(true);
    bff
      .get('/movementDetails', {
        params: {
          documentRef: docReference,
          species: species.name
        }
      })
      .then((res) => {
        if (helpers.response.isValid(res.data, setModal, setPending)) {
          const result = res.data.data[0];

          if (result.documentRef) {
            setData(result);
            if (helpers.cph.isNonCph(result.fromHolding) || helpers.cph.isNonCph(result.toHolding)) {
              helpers.get.comments(getMovementHolding(result), requestId)
                .then((res2) => {
                  if (helpers.response.isValid(res2.data, setModal)) {
                    const address1 = res2?.data?.length > 0 ? res2.data[0].comment : '';

                    setData((prevData) => ({
                      ...prevData,
                      destAddress: createPermitAddress(address1),
                      toHolding: result.fromHolding
                    }));
                  }
                });

              helpers.get.holdingAddress(result.userHolding)
                .then((res2) => {
                  if (helpers.response.isValid(res.data, setModal)) {
                    setData((prevData) => ({
                      ...prevData,
                      fromHolding: result.userHolding,
                      departAddress: res2
                    }));
                  }
                });
            } else {
              vetModuleHelper.fetchVetDetailsForDigitalForm(result.fromHolding, result.movementDate)
                .then((res) => {
                  const vetResult = res.data.data[0];
                  setAttestationRecord(vetResult);
                })
                .catch((error) => {
                  if (!isCancel(error)) {
                    return false;
                  }
                });
              helpers.get.holdingAddress(result.fromHolding)
                .then((res2) => {
                  if (helpers.response.isValid(res.data, setModal)) {
                    setData((prevData) => ({
                      ...prevData,
                      departAddress: res2
                    }));
                  }
                });

              helpers.get.holdingAddress(result.toHolding)
                .then((res2) => {
                  if (helpers.response.isValid(res.data, setModal)) {
                    setData((prevData) => ({
                      ...prevData,
                      destAddress: res2
                    }));
                  }
                });
            }

            helpers.get.metFciWithdrawalPeriods()
              .then((res) => {
                if (helpers.response.isValid(res.data, setModal, setPending)) {
                  const getMetFCIWithdrawalPeriod = (id, periods) => {
                    for (const metFCIWithdrawalPeriod of periods) {
                      if (metFCIWithdrawalPeriod.id === id) {
                        return metFCIWithdrawalPeriod;
                      }
                    }
                    return id;
                  };

                  const fciDetail = result.movementDocument && result.movementDocument.fciDetail ? result.movementDocument.fciDetail : null;

                  if (fciDetail) {
                    setData((prevData) => ({
                      ...prevData,
                      metFCIWithdrawalPeriods: res.data,
                      fciData: {
                        animalsSatisfy: fciDetail.isAllAnimalsFCICompliant ? constants.option.animalsSatisfy.doSatisfy : constants.option.animalsSatisfy.doNotSatisfy,
                        holdingRestrictions: fciDetail.holdingRestrictions,
                        nonComplianceReason: fciDetail.nonCompliantReason,
                        recordFCI: constants.option.recordFCI.yes,
                        withdrawalPeriod: getMetFCIWithdrawalPeriod(fciDetail.withdrawalPeriodMetId, res.data),
                        unfitAnimals: fciDetail.nonCompliantDevices
                      }
                    }));
                  } else {
                    setData((prevData) => ({
                      ...prevData,
                      metFCIWithdrawalPeriods: res.data
                    }));
                  }
                }
              });

            setAnimalTotals(result);
            setTransportInfo(result.movementDocument);
            setProcessingFlags(result);
          }
        }
      })
      .catch((error) => {
        if (!isCancel(error)) {
          setData({});
          setPending(false);
          firstTimePDfDownload = false;
        }
      });
  };

  const getMovementData = () => {
    firstTimePDfDownload = true;
    if (!referenceId) {
      helpers.movementReferenceByRequest(setModal, requestId, requestType, setDocumentReference, generatePDF);
    } else {
      setDocumentReference(referenceId);
      generatePDF(referenceId);
    }
  };

  const setAnimalTotals = (inData) => {
    setData((prevData) => ({
      ...prevData,
      totalIndividualAnimals: inData && inData.devices ? inData.devices?.length : 0,
      totalBatchAnimals: inData && inData.batches ? inData.batches.reduce((total, batch) => total + batch.animalTotal, 0) : 0
    }));
  };

  const getTransportedBy = (tData) => {
    switch (tData.transporterTypeId) {
      case 2:
        return constants.option.transporter.destinationKeeper;
      case 3:
        return constants.option.transporter.haulier;
      case 1:
      default:
        return constants.option.transporter.departureKeeper;
    }
  };

  const setTransportInfo = (inValue) => {
    if (inValue && inValue.transportDetail) {
      const tInfo = inValue.transportDetail;

      if (
        tInfo.transportAuthNumber?.length ||
        tInfo.transportVehicleRegistrationNo?.length ||
        tInfo.transporterTypeId ||
        tInfo.transporterType
      ) {
        setData((prevData) => ({
          ...prevData,
          transportInformation: {
            contactPhone: tInfo.transporter ? tInfo.transporter.telephoneNumber : '',
            departureDateTime: inValue.departureDetail ? inValue.departureDetail.departureDateTime : '',
            durationOfJourney: inValue.departureDetail ? inValue.departureDetail.expectedDurationOfJourney : '',
            recordTransportInformation: constants.option.recordTransportInformation.yes,
            transportAuthNumber: tInfo.transporterAuthNumber,
            transportedBy: getTransportedBy(tInfo),
            transporter: tInfo.transporter,
            vehicleRegistrationNumber: tInfo.transportVehicleRegistrationNo
          }
        }));
      } else {
        setData((prevData) => ({
          ...prevData,
          transportInformation: {
            recordTransportInformation: constants.option.recordTransportInformation.no
          }
        }));
      }
    } else {
      setData((prevData) => ({
        ...prevData,
        transportInformation: {
          recordTransportInformation: constants.option.recordTransportInformation.no
        }
      }));
    }
  };

  const getProcessingStatus = (id, processingItems) => {
    const found = processingItems ? processingItems.find((item) => item.processingFlagId === id) : false;
    return Boolean(found);
  };

  const setProcessingFlags = (inValue) => {
    if (inValue.movementDetailsProcessingFlags) {
      helpers.get.processingFlags(null, constants.option.requestType.movement, helpers.species.nameToId(inValue.species))
        .then((res2) => {
          if (helpers.response.isValid(res2.data, setModal)) {
            const allRecs = res2.data.map((flag) => ({
              ...flag,
              value: getProcessingStatus(flag.id, inValue.movementDetailsProcessingFlags)
            }));

            setData((prevData) => ({
              ...prevData,
              processingFlags: {
                exempt: 'yes',
                exemptions: [],
                all: allRecs
              }
            }));
          }
        })
        .catch((error) => {
          if (!isCancel(error)) {
            setProcessingFlags({ exempt: 'yes', exemptions: [] });
          }
        });
    } else {
      setData((prevData) => ({
        ...prevData,
        processingFlags: {
          exempt: 'yes',
          exemptions: []
        }
      }));
    }
  };

  const printReady = () => {
    return (
      data.documentRef &&
      data.departAddress &&
      data.destAddress &&
      data.metFCIWithdrawalPeriods &&
      data.totalIndividualAnimals !== null &&
      data.totalBatchAnimals !== null &&
      data.transportInformation &&
      data.fciData &&
      data.processingFlags
    );
  };

  return (
    <>
      {data.species !== 'Deer' && printReady() &&
        <BlobProvider document={<MovementDocumentPDF attestationRecord={attestationRecord} data={data} />}>
          {({ blob, error, loading, url }) => {
            if (!loading && url && blob && Object.prototype.hasOwnProperty.call(pdfUrl, 'current') && firstTimePDfDownload) {
              downloadUrl(url, documentReference);
              firstTimePDfDownload = false;
              pdfUrl = url;
            }

            if (error) {
              return <div>{error}</div>;
            }

            return null;
          }}
        </BlobProvider>
      }
      {data.species === 'Deer' && printReady() &&
        <BlobProvider document={<MovementDocumentDeerPDF attestationRecord={attestationRecord} data={data} />}>
          {({ blob, error, loading, url }) => {
            if (!loading && url && blob && Object.prototype.hasOwnProperty.call(pdfUrl, 'current') && firstTimePDfDownload) {
              downloadUrl(url, documentReference);
              firstTimePDfDownload = false;
              pdfUrl = url;
            }

            if (error) {
              return <div>{error}</div>;
            }

            return null;
          }}
        </BlobProvider>
      }
      <Button
        buttonType={buttonType ? buttonType : 'secondary'}
        disabled={!printReady() && pending}
        label={!printReady() && pending ? 'button.generating' : (label ? label : 'button.printMovementDocument') }
        onClick={getMovementData}
      />
    </>
  );
};

PDFButton.propTypes = {
  buttonType: PropTypes.string,
  label: PropTypes.string,
  referenceId: PropTypes.string,
  requestId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  requestType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  setModal: PropTypes.func.isRequired
};

export default PDFButton;
